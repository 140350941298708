import logo from './logo.svg';
import './App.css';
import tgLogo from './assets/tg_logo.png';
import xLogo from './assets/x_logo_white.png';
import bgImg from './assets/bg_img.png'
import styled from 'styled-components';
import cat from './assets/samurai_cat.png';
import dexTools from './assets/DEXTools_ black.png';

const PageWrapperDiv = styled.div` 
    display: flex;  
    flex-direction: column;  
    align-items: center;        
    background-image:  url(${bgImg});
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 95vh;
    
    @media screen and (max-width: 768px) {      
        margin: 0px;        
        
        // margin-top: 85px;        
    }
`;

const SectionDiv = styled.div`
    padding: 20px;
     @media screen and (max-width: 768px) { 
      display: flex;
      flex-direction: column;
      width: 100%;
     }
`;

const TitleDiv = styled.div`
    width: 100%;        
    display: flex;
    justify-content: center;
    
    font-size: 4em;
    font-weight: bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
    color: Yellow;
    @media screen and (max-width: 768px) {      
      font-size: 1.7em;      
      
    }
`;

const SectionTitleDiv = styled.div`
display: flex;
justify-content: center;
font-size: 4em;
font-weight: bold;
text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
color: orange;
@media screen and (max-width: 768px) {      
  font-size: 1.7em;      
  
}
`;


const SectionSubTitleDiv = styled.div`
display: flex;
justify-content: center;
font-size: 3em;
font-weight: bold;
text-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
color: orange;
padding-bottom: 10px;
@media screen and (max-width: 768px) {      
  font-size: 1.7em;      
  
}
`;

const SectionSubParaDiv = styled.div`
display: flex;
justify-content: center;
font-size: 1.4em;
text-align: center;
font-weight: bold;
text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
color: black;
padding-bottom: 10px;
@media screen and (max-width: 768px) {      
  font-size: 1em;      
  width: 90%;
}
`;
const CatImage = styled.img`
  width: 500px;
  align-self: center;    
  position: absolute;
    top: 50%; /* Position at 50% from the top */
    left: 60%; /* Position at 50% from the left */
    transform: translate(-50%, -50%); /* Center the element */
  @media screen and (max-width: 768px) {      
    width: 250px;
    left: 50%;    
    transform: translate(-25%, -50%); /* Center the element */
  }
`;

const DescriptionsDiv = styled.div`
display: flex;
justify-content: center;
column-gap: 1rem;
 @media screen and (max-width: 768px) {    
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  row-gap: 1rem;
  column-gap: 0rem;
 }
`;

const DescriptionDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #fadb08;
border: 1px solid orange;
border-radius: 30px;
color: #3b3a31;
width: 250px;
height: 200px;
font-size: 2em;
padding: 10px;

  @media screen and (max-width: 768px) {
    width: 98%;
  }

`;


const StyledButton = styled.a`
display: flex;
justify-content: center;
    position: relative;
    font-family: Laila Bold;
    color: white;
    border: 1px solid #060fce;
    border-radius: 90px;
    background-color: #060fce;    
    width: auto;
    padding: 20px;
    font-size: 1.3em;
    min-width: 100px;
    max-width: 200px;
    border: 2px solid white;
    text-decoration: none;
    &:hover{
        cursor: pointer;
    }
    &:disabled{
        cursor: not-allowed;
        color: grey;
    }
    @media screen and (max-width: 768px) {
      font-size: .8em;
      min-width: 100px;
      max-width: 35%;
      padding: 5px;  
      align-self: center;   
    }
`;

const SocialsWrapper = styled.div`
 display: flex;
 padding-left: 20px;
 align-items: center;
 column-gap: 2rem;

@media screen and (max-width: 768px) {
  
  
  justify-items: center;
  padding: 0px;
}
`;

const ButtonsWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  row-gap: .5rem;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
function App() {
  return (    
    <>
    <PageWrapperDiv>
       <TitleDiv style={{paddingTop: "50px",fontFamily: "Matura MT Script Capitals"}}>Savannah</TitleDiv>
       <TitleDiv style={{fontFamily: "Matura MT Script Capitals"}}>The Solana Slayer</TitleDiv>
       <ButtonsWrapperDiv>
          <TitleDiv style={{fontSize: "1em",zIndex: "5"}}>CA: CeoRHhYivzT9hMpdAvs7BKFRMEfv2ezWLfm8CX7D4ekR</TitleDiv>
          <StyledButton href="https://raydium.io/swap/?outputCurrency=CeoRHhYivzT9hMpdAvs7BKFRMEfv2ezWLfm8CX7D4ekR" target='_blank' rel="noreferrer">BUY $SAVCAT</StyledButton>
          <StyledButton href="https://www.dextools.io/app/en/solana/pair-explorer/8TgdTZnyzjTSS6iZooWbDg5fvuCwETRjgC2V1CG2rpsb?t=1712680341017"  target='_blank' rel="noreferrer"><img style={{width: "60%"}} src={dexTools} alt=""/></StyledButton>
          <SocialsWrapper>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", padding: "10px", borderRadius: "90px"}}>
                  <a  href="https://twitter.com/SavannahSlaySOL" target="_blank" rel="noreferrer" ><img style={{height: "40px",}} src={xLogo} alt=""/></a></div>
                <div style={{zIndex: "5"}}>
                  <a  href="https://t.me/savannahsolanaslayer" target="_blank" rel="noreferrer"><img style={{height: "80px"}} src={tgLogo} alt="" /></a></div>
            </SocialsWrapper>
       </ButtonsWrapperDiv>
       
       <CatImage src={cat} alt="" />
    </PageWrapperDiv>
    <SectionDiv>
    <DescriptionsDiv>     
      </DescriptionsDiv>
      <SectionTitleDiv>About $SAVCAT</SectionTitleDiv>
      <SectionSubTitleDiv>A journey of a thousand miles begins with a single step.</SectionSubTitleDiv>
      <SectionSubParaDiv >Savannah is the most fierce feline to be known. A cross between a domestic cat and a wild African cat.</SectionSubParaDiv>
      <SectionSubParaDiv >The dogs, frogs and wolves will not stand a chance.</SectionSubParaDiv>
      <DescriptionsDiv>
        <DescriptionDiv>
          Liquidity Burnt
        </DescriptionDiv>
        <DescriptionDiv>
          Zero Tax
        </DescriptionDiv>
        <DescriptionDiv>
          Contract revoked
        </DescriptionDiv>
        <DescriptionDiv style={{display: "flex", flexDirection: "column"}}>
          <div>10B Supply</div>
          <div>90% Liquidity</div>
          <div>10% Growth</div>
        </DescriptionDiv>
      </DescriptionsDiv>
    </SectionDiv>
    </>
  );
}

export default App;
